<template>
  <div id="airports" class="font-sans" data-test="airports">
    <h2 class="text-base font-cocogoose xs:text-xl md:text-3xl">
      {{ $i18n("general.our-airports") }}
    </h2>
    <div
      v-if="inactiveAirports.length"
      class="mt-7 mb-5 inactive-airports-header"
      data-test="airports-header"
    >
      <div
        class="inline text-[14px] xs:text-base"
        v-html="
          $i18n('templates.reserve-now', {
            activeLocations: airports.length,
            merchants: totalParkings,
          })
        "
      />
      <div
        class="inline text-[14px] xs:text-base"
        v-html="$i18n('templates.more-airports')"
      />
    </div>
    <template v-for="country in countriesSorted">
      <div :key="country" class="mt-5 md:mt-10">
        <div class="flex place-content-between mb-4 md:mb-6">
          <h3 class="text-[14px] xs:text-md md:text-xl font-cocogoose">
            {{ country }}
          </h3>
        </div>
        <div
          class="airports-container-no-scroll flex flex-nowrap xs:flex-wrap overflow-x-scroll xs:overflow-x-hidden xs:grid xs:grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-10 md:grid-cols-3 md:gap-12 lg:gap-8 lg:grid-cols-4"
        >
          <template v-for="airport in getAirports(country)">
            <Airport
              :key="airport.id"
              class="max-h-min"
              :class="{
                'mr-8': iOSversion.version && iOSversion.version <= 14,
              }"
              :airport="airport"
            />
          </template>
        </div>
      </div>
    </template>
    <transition name="inactiveAirports">
      <div
        v-if="inactiveAirports.length"
        v-show="show"
        ref="inactiveAirportsList"
        class="mt-12"
      >
        <div class="mt-7 mb-2">
          {{ $i18n("templates.inactive-airports") }}
        </div>
        <ul>
          <li v-for="airport in inactiveAirports" :key="airport.id">
            <a class="text-blue-600 font-bold text-[14px] xs:text-base" :href="`/${airport.slug}/`">{{
              airport.name
            }}</a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import Airport from "~/components/airports/AirportCard.vue";

export default {
  components: {
    Airport,
  },
  data() {
    return {
      countries: {},
      show: false,
    };
  },
  computed: {
    inactiveAirports() {
      return this.$store.state.inactiveAirports;
    },
    totalParkings() {
      return this.$store.state.totalParkings;
    },
    airports() {
      return [...this.$store.state.airports].map((val) => ({
        ...val,
        airportImage: val.airportImage.replace(
          "https://assets.parkos.com",
          "/_nuxt_home_page"
        ),
      }));
    },
    language() {
      return this.$store.state.language;
    },
    countriesSorted() {
      return Object.keys(this.countries).sort((a, b) => {
        const country = this.language.native_name.replace(
          /(\s*)?\((.+)?\)(\s*)?/,
          ""
        );
        if (country === a) {
          return Number.MIN_SAFE_INTEGER;
        }
        if (country === b) {
          return Number.MAX_SAFE_INTEGER;
        }
        const lengthA = this.countries[a].length;
        const lengthB = this.countries[b].length;
        return lengthA === lengthB ? a.localeCompare(b) : lengthA - lengthB;
      });
    },
    iOSversion() {
      if (process.server) {
        return { status: false, version: false, info: "" };
      }
      let device;
      let version;
      if (/iP(hone|od|ad)/.test(navigator.platform)) {
        version = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        device = {
          status: true,
          version: parseInt(version[1], 10),
          info: `${parseInt(version[1], 10)}.${parseInt(
            version[2],
            10
          )}.${parseInt(version[3] || 0, 10)}`,
        };
      } else {
        device = { status: false, version: false, info: "" };
      }
      return device;
    },
  },
  mounted() {
    window.addEventListener("hashchange", this.locationHashListener);
  },
  beforeDestroy() {
    window.removeEventListener("hashchange", this.locationHashListener);
  },
  created() {
    this.airports.forEach((airport) => {
      if (!this.countries[airport.country.name]) {
        this.countries[airport.country.name] = [];
      }

      this.countries[airport.country.name].push(airport);
    });
  },
  methods: {
    getAirports(country) {
      const airports = [...this.countries[country]];
      return airports.sort((a, b) => a.name.localeCompare(b.name));
    },
    locationHashListener() {
      if (window.location.hash === "#airports-todo") {
        window.location.hash = "";
        setTimeout(() => {
          this.show = true;
          setTimeout(() => {
            this.$refs.inactiveAirportsList.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
/* Chrome, Safari and Opera */
.airports-container-no-scroll::-webkit-scrollbar {
  display: none;
}

.airports-container-no-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.inactive-airports-header {
  a {
    @apply text-blue-600;
    &:hover {
      @apply underline;
    }
  }
}
.inactiveAirports-enter-active,
.inactiveAirports-leave-active {
  @apply transition transition-opacity duration-1000;
}
.inactiveAirports-enter,
.inactiveAirports-leave-to {
  @apply opacity-0;
}
</style>
