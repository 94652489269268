<template>
  <div class="usps-wrapper" data-test="ups-wrapper">
      <div class="usps__title" data-test="ups-title">
        {{ $i18n("templates.parkos-usps") }}
      </div>
      <div data-test="ups-container" class="usps__container">
        <div data-test="ups-section-checked" class="usps__section">
          <div data-test="ups-card-checked" class="usps__section-card">
            <IconCar class="usps__section-card__icon" data-test="ups-card-icon-checked"/>
            <div>
              <div class="usps__section-card__title" data-test="ups-card-title-checked">
                {{ $i18n("templates.parkos-usp-title-1") }}
              </div>
              <div
                class="usps__section-card__description"
                data-test="ups-card-description-checked"
                v-html="$i18n('templates.parkos-usp-1')"
              />
            </div>
          </div>
        </div>
        <div class="usps__section" data-test="ups-section-deals">
          <div class="usps__section-card" data-test="ups-card-deals">
            <IconThumbsUp class="usps__section-card__icon" data-test="ups-card-icon-deals"/>
            <div>
              <div class="usps__section-card__title" data-test="ups-card-title-deals">
                {{ $i18n("templates.parkos-usp-title-2") }}
              </div>
              <div
                class="usps__section-card__description"
                data-test="ups-card-description-deals"
                v-html="$i18n('templates.parkos-usp-2')"
              />
            </div>
          </div>
        </div>
        <div class="usps__section" data-test="ups-section-cancel">
          <div class="usps__section-card" data-test="ups-card-cancel">
            <Icon24 class="usps__section-card__icon" data-test="ups-card-icon-cancel"/>
            <div>
              <div class="usps__section-card__title" data-test="ups-card-title-cancel">
                {{ $i18n("templates.parkos-usp-title-4") }}
              </div>
              <div
                class="usps__section-card__description"
                data-test="ups-card-description-cancel"
                v-html="$i18n('templates.parkos-usp-4')"
              />
            </div>
          </div>
        </div>
        <div class="usps__section" data-test="ups-section-cancel-number-customers">
          <div class="usps__section-card" data-test="ups-card-number-customers">
            <IconCheck class="usps__section-card__icon" data-test="ups-card-icon-number-customers"/>
            <div>
              <div class="usps__section-card__title" data-test="ups-card-title-number-customers">
                {{ $i18n("templates.parkos-usp-title-3") }}
              </div>
              <div
                class="usps__section-card__description"
                data-test="ups-card-description-number-customers"
                v-html="$i18n('templates.parkos-usp-3')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import IconCar from "~/components/icons/usp/IconCar.vue";
import IconThumbsUp from "~/components/icons/usp/IconThumbsUp.vue";
import Icon24 from "~/components/icons/usp/Icon24.vue";
import IconCheck from "~/components/icons/usp/IconCheck.vue";

export default {
  components: {
    IconCar,
    IconThumbsUp,
    Icon24,
    IconCheck,
  },
};
</script>

<style lang="scss">
.usps {
  &-wrapper {
    @apply font-sans;
  }

  &__title {
    @apply text-[16px] font-cocogoose xs:text-xl md:text-[28px] mb-[40px];
  }

  &__container {
    @apply grid auto-cols-auto sm:grid-cols-2 gap-y-2 gap-x-4 lg:grid-cols-4 md:gap-x-10;
  }

  &__section {
    &-card {
      @apply flex items-start gap-x-[10px];

      &__icon {
        @apply fill-current text-blue-600 w-14 h-14 -mt-1 -ml-2;
      }
      &__title {
        @apply text-[14px] xs:text-base font-cocogoose mb-2.5;
      }
      &__description {
        @apply text-[14px] xs:text-base;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          @apply mb-0 font-bold;
        }
      }
    }
  }
}
</style>
