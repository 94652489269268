import { render, staticRenderFns } from "./Top3Air.vue?vue&type=template&id=a809bbc0&scoped=true"
import script from "./Top3Air.vue?vue&type=script&lang=js"
export * from "./Top3Air.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a809bbc0",
  null
  
)

export default component.exports